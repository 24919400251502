import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null); // State to store the CAPTCHA value

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    });
    // Clear error message when user starts typing
    setFormErrors({
      ...formErrors,
      [category]: '',
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if CAPTCHA is completed
    if (!captchaValue) {
      setStatus({ success: false, message: 'Please complete the CAPTCHA.' });
      return;
    }

    // Check if all fields are filled
    const errors = {};
    if (formDetails.firstName.trim() === '') {
      errors.firstName = 'First name is required';
    }
    if (formDetails.lastName.trim() === '') {
      errors.lastName = 'Last name is required';
    }
    if (formDetails.email.trim() === '') {
      errors.email = 'Email is required';
    }
    if (formDetails.phone.trim() === '') {
      errors.phone = 'Phone number is required';
    }
    if (formDetails.message.trim() === '') {
      errors.message = 'Message is required';
    }

    if (Object.keys(errors).length > 0) {
      // Display errors and prevent form submission
      setStatus({ success: false, message: 'Please fill in all required fields.' });
      setFormErrors(errors);
      return;
    }

    // All fields are filled and CAPTCHA is completed, proceed with form submission
    setButtonText("Sending...");
    try {
      let response = await fetch("https://mihir9636.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(formDetails),
      });
      setButtonText("Send");
      let result = await response.json();
      setFormDetails(formInitialDetails);
      if (result.code === 200) {
        setStatus({ success: true, message: 'Message sent successfully' });
      } else {
        setStatus({ success: false, message: 'Something went wrong, please try again later.' });
      }
    } catch (error) {
      setButtonText("Send");
      setStatus({ success: false, message: 'An error occurred. Please try again later.' });
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div>
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                        {formErrors.firstName && <p className="error">{formErrors.firstName}</p>}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                        {formErrors.lastName && <p className="error">{formErrors.lastName}</p>}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                        {formErrors.email && <p className="error">{formErrors.email}</p>}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                        {formErrors.phone && <p className="error">{formErrors.phone}</p>}
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                        {formErrors.message && <p className="error">{formErrors.message}</p>}
                      </Col>
                      <Col xs={12} className="px-1 d-flex align-items-center">
                        <ReCAPTCHA
                          sitekey="6LfYiPQpAAAAAL-O6dodtfleQcXvhzGsI9kQVwQ6"
                          onChange={handleCaptchaChange}
                        />  </Col>
                      <Col xs={12} className="px-1 d-flex align-items-center">

                        <button type="submit"><span>{buttonText}</span></button>
                        {status.message && <p className={status.success === false ? "danger" : "success"}>{status.message}</p>}
                      </Col>
                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
