import { Container, Row, Col } from "react-bootstrap";
import { ResumeDownload } from "./ResumeDownload"; // Import the new component
import logo from "../assets/img/logo.webp";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <ResumeDownload /> 
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/mihir-sharma-9914711a0?utm_source=share&utm_campaign=share_via&utm_content=profile" target="_blank"><img src={navIcon1} alt="Icon" /></a>
              {/* <a href="#"><img src={navIcon2} alt="Icon" /></a> */}
              <a href="https://www.instagram.com/mihir9636/" target="_blank"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>© 2024 Mihir Sharma. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
