import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.webp';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import { Link, useNavigate } from "react-router-dom";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const handleNavClick = (value) => {
    onUpdateActiveLink(value);
    navigate(`/${value === 'home' ? '' : value}`);
  }

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={() => onUpdateActiveLink('home')}>
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={HashLink} to="/#home" style={{ fontSize: '18px' }} className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => handleNavClick('home')}>Home</Nav.Link>
            <Nav.Link as={HashLink} to="/#skills" style={{ fontSize: '18px' }} className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => handleNavClick('skills')}>Skills</Nav.Link>
            <Nav.Link as={HashLink} to="/#projects" style={{ fontSize: '18px' }} className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => handleNavClick('projects')}>Projects</Nav.Link>
            <Nav.Link as={Link} to="/blogs" style={{ fontSize: '18px' }} className={activeLink === 'blogs' ? 'active navbar-link' : 'navbar-link'} onClick={() => handleNavClick('blogs')}>Blogs</Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/mihir-sharma-9914711a0?utm_source=share&utm_campaign=share_via&utm_content=profile" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="" /></a>
              <a href="https://www.instagram.com/mihir9636/" target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="" /></a>
            </div>
            <HashLink to='#connect'>
              <button className="vvd"><span>Let’s Connect</span></button>
            </HashLink>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
