import React, { useEffect, useState } from 'react';
import BlogCard from './BlogCard';
import { DefaultFooter } from './DefaultFooter';
import './Blogs.css';

export const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://mihir9636.com/api/getblogs');
        const blogData = await response.json();

        setBlogs(blogData);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFooter(true);
    }, 2000);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="blogs-container"> {/* Apply the CSS class */}
      {blogs.map((blog) => (
        <BlogCard
          id={blog._id}
          title={blog.title}
          date={blog.date}
          media={blog.media}
          mediaType={blog.mediaType}
          content={blog.content}
          details={blog.details}
          initialLikeCount={blog.likes}
        />
      ))}
      {showFooter && <DefaultFooter />}
    </div>
  );
};
