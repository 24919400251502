import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./unuse/ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const project_1 = [
    {
      title: "Superface",
      description: "Attendance System App",
      imgUrl: projImg1,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div>
                  <h2>Projects</h2>
                  <p>Below are the projects I've developed or contributed to. Throughout my projects, I've consistently applied technology to optimize operations, boost productivity, and catalyze business expansion.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Link eventKey="first">SuperFace</Nav.Link>
                      <Nav.Link eventKey="second">TriggerPOS</Nav.Link>
                      <Nav.Link eventKey="third">PriceChecker</Nav.Link>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <p>Introducing SuperFace, an advanced attendance system app designed for seamless employee tracking. With cutting-edge features including face recognition, fingerprint scanning, and NFC card support, SuperFace offers unparalleled versatility in attendance management. Its fully automated data syncing capabilities ensure that attendance records are effortlessly pushed to the cloud, enhancing accessibility and data security. Coupled with a user-friendly interface and intuitive user experience, SuperFace sets a new standard in modern attendance solutions.</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <p>TriggerPOS is a versatile multi-industry application catering to diverse sectors including restaurants, retail, parking management, and payment collection. It serves as a comprehensive solution tailored to meet the unique needs of each industry, empowering businesses to streamline operations and enhance customer experiences across various domains.</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>Price Checker is an intuitive and efficient software designed for specific devices with a barcode scanner. It assists users in conveniently checking prices. This user-friendly application allows individuals to quickly access accurate pricing information for a wide range of products available at Mr. DIY stores.</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background"></img>
    </section>
  )
}
