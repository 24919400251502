import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Cursor.css'; // Make sure to create this CSS file with the necessary styles

const Cursor = () => {
    const cursorRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            gsap.to(cursorRef.current, {
                x: e.clientX,
                y: e.clientY,
                stagger: 0.002,
            });
        };

        const handleMouseEnter = () => {
            gsap.to(cursorRef.current, {
                scale: 1.4,
                opacity: 1,
            });
        };

        const handleMouseLeave = () => {
            gsap.to(cursorRef.current, {
                scale: 1,
                opacity: 0.6,
            });
        };

        window.addEventListener('mousemove', handleMouseMove);
        document.querySelectorAll('.menubar, a, .navigation-close').forEach(el => {
            el.addEventListener('mouseenter', handleMouseEnter);
            el.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            document.querySelectorAll('.menubar, a, .navigation-close').forEach(el => {
                el.removeEventListener('mouseenter', handleMouseEnter);
                el.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);

    return <div className="cursor" ref={cursorRef}></div>;
};

export default Cursor;
