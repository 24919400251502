import React, { useState, useRef } from 'react';
import './Blogs.css'; // Adjust the path based on your file structure
import myIcon from '../assets/img/myIcon.webp';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import TrackVisibility from 'react-on-screen';

export default function BlogCard({ id, title, date, media, mediaType, content, details, initialLikeCount }) {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(initialLikeCount);
  const [animateLike, setAnimateLike] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const toggleLike = async () => {
    const newValue = liked ? -1 : 1; // Increment or decrement based on liked state
    const action = liked ? 'unlike' : 'like'; // Log action based on liked state

    try {
      const response = await fetch('https://mihir9636.com/api/incrementlike', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ _id: id, value: newValue }),
      });
      const data = await response.json();

      if (data.code === 200) {
        setLikeCount(likeCount + newValue); // Update like count based on response
        setLiked(!liked); // Toggle liked state
        if (!liked) { // Only animate on like
          setAnimateLike(true);
          setTimeout(() => setAnimateLike(false), 500); // Handle animation
        }
      } else {
        console.error(`Error ${action}ing the blog:`, data.status);
      }
    } catch (error) {
      console.error(`Error ${action}ing the blog:`, error);
    }
  };

  const renderMedia = (isVisible) => {
    switch (mediaType) {
      case 'image':
        return <img className="blog-image" src={media} alt="blog media" />;
      case 'video':
        if (videoRef.current) {
          isVisible ? videoRef.current.play() : videoRef.current.pause();
        }
        return (
          <video ref={videoRef} className="blog-video" loop muted={isMuted}>
            <source src={media} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case 'gif':
        return <img className="blog-image" src={media} alt="blog gif" />;
      default:
        return null;
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const shareBlog = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: content,
        url: window.location.href+"/#"+id,
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API not supported.');
    }
  };

  return (
    <div className="blog-card">
      <div className="blog-card-header">
        <img className="avatar" src={myIcon} alt="myicon" />
        <div className="header-text">
          <h2 className="title">{title}</h2>
          <p className="date">{date}</p>
        </div>
      </div>
      <TrackVisibility>
        {({ isVisible }) => renderMedia(isVisible)}
      </TrackVisibility>
      <div className="blog-content">
        <p>{content}</p>
      </div>
      
      <div className="blog-actions">
        <IconButton
          aria-label="add to favorites"
          onClick={toggleLike}
          className={`${animateLike ? 'animate-like' : ''} ${liked ? 'liked' : ''}`}
        >
          {liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton>
        <span className="like-count">{likeCount} likes</span>
        <IconButton aria-label="share" onClick={shareBlog}>
          <ShareIcon />
        </IconButton>
        {mediaType === 'video' && (
          <IconButton aria-label="toggle mute" onClick={toggleMute}>
            {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
        )}
      </div>
    </div>
  );
}
