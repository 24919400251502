import React from 'react';

export const ResumeDownload = () => {
  return (
    <div style={{marginTop:'40px', display: 'flex', alignItems: 'center' }}>
      <h3 style={{ marginRight: '100px' }}>Download My Resume</h3>
      <a href="https://mihir9636.com/Mihir%20Sharma%20Resume%20v4.pdf" download>
        <button className="btn btn-primary">Download</button>
      </a>
    </div>
  );
};
