import React from "react";
import Particles from "@tsparticles/react";

const particlesLoaded = (container) => {
    console.log(container);
};

const options = {
    fullScreen: {
        enable: false
    },
    fpsLimit: 120,
    interactivity: {
        events: {},
        modes: {
            push: {
                quantity: 5,
            },
            repulse: {
                distance: 50,
                duration: 0.5,
            },
        },
    },
    particles: {
        color: {
            value: "#ffffff",
        },
        links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 0.5,
        },
        move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: true,
            speed: 0.5,
            straight: true,
        },
        number: {
            density: {
                enable: true,
            },
            value: 80,
        },
        opacity: {
            value: 0.5,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 3, max: 5 },
        },
    },
    detectRetina: true,
};

export const ParticleComponent = () => {
    const particlesLoadedHandler = (container) => {
        console.log(container);
        if (particlesLoaded) {
            particlesLoaded(container);
        }
    };

    return (
        <div className="particles-container">
            <Particles 
                id="tsparticles" 
                particlesLoaded={particlesLoadedHandler} 
                options={options} 
            />
        </div>
    );
};